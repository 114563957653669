import Vue from "vue";
import Vuex from "vuex";
import * as _ from "lodash";
import { DASHBOARD_CARD_ARR } from "@/constants";
import { locations } from "@/constants/locations";

// modules
import notificationModule from "./notification";
import packagesModule from "./packages";
import companyModule from "./company";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    drawer: true,
    user: null,
    appLoading: false,
    headerExpansion: true,
    // ----
    statsLoading: false,
    stats: [],
    formattedStats: [],
    statsCount: 0,

    //
    locations,
    evaluationForms: [],
    // -----
    jobs: [],
    profileLinks: localStorage.getItem("profileLinks")
      ? JSON.parse(localStorage.getItem("profileLinks"))
      : [],
    auto_interviews: [],
    interview_questions: [],
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_APP_LOADING(state, payload) {
      state.appLoading = payload;
    },
    SET_STATS_LOADING(state, payload) {
      state.statsLoading = payload;
    },
    SET_JOBS(state, payload) {
      state.jobs = payload;
    },
    SET_STATS(state, { stats, count }) {
      state.stats = stats;
      state.statsCount = count;
      state.formattedStats = stats
        .reduce((acc, current) => {
          const { status } = current;
          const index = acc.findIndex((a) => a.title === status);
          if (status === 0) return acc;
          if (index !== -1) {
            acc[index] = {
              ...acc[index],
              value: acc[index].value + 1,
            };
          } else {
            acc.push({
              title: status,
              value: 1,
              percentage: 6,
              up: true,
            });
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.value > b.value) {
            return -1;
          }
          if (a.value < b.value) {
            return 1;
          }
          return 0;
        });
    },
    SET_PROFILE_LINKS(state, links) {
      state.profileLinks = links;
      localStorage.setItem("profileLinks", JSON.stringify(state.profileLinks));
    },
    SET_HEADER_EXPANSION(state, payload) {
      state.headerExpansion = payload;
    },
    CLEAR_STORE(state) {
      state.stats = [];
      state.jobs = [];
    },
    SET_AUTO_INTERVIEWS_LIST(state, payload) {
      state.auto_interviews = payload;
    },
    SET_INTERVIEW_QUESTIONS_LIST(state, payload) {
      state.interview_questions = payload;
    },
  },

  getters: {
    SHOW_AI_SCORE(store) {
      if (!store.user) return false;
      const { name } = store.user.company;
      if (name === "evampsaanga") return false;
      return true;
    },
    DASHBOARD_CARDS(state) {
      return DASHBOARD_CARD_ARR.map((card) => {
        let value = 0;

        const index = state.formattedStats.findIndex(
          (a) => a.title.toLowerCase() === card.title.toLowerCase()
        );

        if (index !== -1) {
          value = state.formattedStats[index].value;
        }

        return {
          ...card,
          value,
        };
      });
    },
    FUNNEL_DATA_SET(state) {
      let funnelLabels = [
        "new",
        "shortlisted",
        "schedule interview",
        "pending decision",
        "job offered",
      ];

      return funnelLabels
        .map((funnel) => {
          const index = state.formattedStats.findIndex(
            (f) => f.title.toLowerCase() == funnel.toLowerCase()
          );
          let value = 0;
          if (index !== -1) {
            value = state.formattedStats[index].value;
          }

          let label = funnel;
          // let split = label.split(" ");
          // if (split.length > 1) label = split.join("\n");

          return {
            name: label,
            value,
          };
        })
        .sort((a, b) => {
          if (a.value > b.value) {
            return -1;
          }
          if (a.value < b.value) {
            return 1;
          }
          return 0;
        });
    },
  },
  actions: {
    async FETCH_USER(ctx) {
      const commit = ctx.commit;
      try {
        const resp = await Vue.axios.post("get/user", {
          token: localStorage.getItem("token"),
        });
        const user = resp.data.data;
        user.role = Number(user.role);
        commit("SET_USER", user);
        if (user.role === 0) {
          ctx.dispatch("package/FETCH_PACKAGES");
          ctx.dispatch("company/FETCH_ALL_COMPANIES");
        }
        return;
      } catch (error) {
        console.log(error);
        return;
      }
    },

    async FETCH_STATS({ commit, state }) {
      try {
        if (state.stats.length > 0) return;
        commit("SET_STATS_LOADING", true);
        const { company_id } = state.user;
        const params = {
          whereHas: {
            key: "jobPost",
            value: "company_id," + company_id,
          },
          company_id: company_id,
        };

        const resp = await Vue.axios.get("application/stats", params);
        const { data, count } = resp.data;
        commit("SET_STATS", { stats: data, count });
        commit("SET_STATS_LOADING", false);
      } catch (error) {
        console.log(error);
        commit("SET_STATS_LOADING", false);
        return;
      }
    },

    async FETCH_JOBS({ commit, state }) {
      try {
        if (state.jobs.length) return;

        // let company = window.location.hostname;
        // if (company === "localhost") {
        //   company = "career.easy2employ.com";
        // }

        // const whereHas = {
        //   key: "company",
        //   value: `url,${company}`,
        // };

        let url = `job?&company_id=${state.user.company_id}`;
        const resp = await Vue.axios.get(url);

        let jobs = resp.data.data;
        const uniqueJobs = _.uniqBy(jobs, "title");

        uniqueJobs.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateB - dateA;
        });

        commit("SET_JOBS", uniqueJobs);
        return;
      } catch (error) {
        console.log(error);
        return;
      }
    },
    async ADD_COMPANY_DETAILS({ commit }, payload) {
      console.log("dfdasf", payload);

      try {
        const resp = await Vue.axios.post("/company", {
          token: localStorage.getItem("token"),
        });
        console.log(resp);
        const user = resp.data.data;
        user.role = Number(user.role);
        commit("SET_USER", user);
        return;
      } catch (error) {
        console.log(error);
        return;
      }
    },
  },
  modules: {
    notification: notificationModule,
    package: packagesModule,
    company: companyModule,
  },
});
