import service from '../../axios/index.js';

export default {
  namespaced: true,
  state: {
    notifications: [],
    count: 0,
    loading: false,
  },
  mutations: {
    SET_NOTIFICATIONS(state, { notifications, count }) {
      state.notifications = notifications;
      state.count = count;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    MARK_READ(state, id) {
      state.notifications = state.notifications.map((notification) => {
        if (notification._id === id) notification.seen = true;
        return notification;
      });
      state.count = state.count - 1;
    },
  },
  actions: {
    async FETCH_NOTIFICATIONS({ commit }) {
      try {
        commit('SET_LOADING', true);
        const resp = await service.get('/notification');
        const data = resp.data;
        commit('SET_NOTIFICATIONS', {
          notifications: data.data,
          count: data.new,
        });
      } catch (error) {
        console.log(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async READ_NOTIFICATION({ commit }, id) {
      await service.post('/notification/seen', { seen: id });
      commit('MARK_READ', id);
    },
  },
};
