export const COLORS = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#fac858',
  '#ee6666',
];

export const DASHBOARD_CARD_ARR = [
  {
    icon: 'mdi-account',
    title: 'new',
    value: 0,
    valueColor: '#EF4B35',
    lastPercentage: 0,
    up: true,
    lastText: 'From last 10 days',
  },
  {
    icon: 'mdi-file-multiple',
    title: 'Shortlisted',
    value: 48,
    valueColor: '#FAAD36',
    lastPercentage: 0,
    up: true,
    lastText: 'From last 10 days',
  },
  {
    icon: 'mdi-calendar-arrow-right',
    title: 'Schedule Interview',
    value: 203,
    valueColor: '#2E3F51',
    lastPercentage: 0,
    up: true,
    lastText: 'From last 10 days',
  },
  {
    icon: 'mdi-clock-outline',
    title: 'pending decision',
    value: 0,
    valueColor: '#2E3F51',
    lastPercentage: 0,
    up: true,
    lastText: 'From last 10 days',
  },
  {
    icon: 'mdi-briefcase',
    title: 'job offered',
    value: 16,
    valueColor: '#90278F',
    lastPercentage: 0,
    up: false,
    lastText: 'From last 10 days',
  },
  {
    icon: 'mdi-handshake',
    title: 'Joined',
    value: 78,
    valueColor: '#28a745',
    lastPercentage: 0,
    up: true,
    lastText: 'From last 10 days',
  },
];

export const CANDIDATE_SUB_ROUTES = [
  {
    label: 'Resume',
    to: '#resume',
    roles: [1, 2, 3, 4],
  },
  {
    label: 'Evaluation',
    to: '#evaluation',
    roles: [1, 2, 3, 4],
  },
  {
    label: 'Questionnaire',
    to: '#questionnaire',
    roles: [1, 2],
  },
  {
    label: 'Notes',
    to: '#notes',
    roles: [1, 2],
  },
  {
    label: 'Email Correspondences',
    to: '#correspondences',
    roles: [1, 2],
  },
];

export const COMPANY_USER_ROLES = [
  {
    value: 1,
    label: 'Super Admin',
  },
  {
    value: 2,
    label: 'Company Admin',
  },
  {
    value: 3,
    label: 'Employee',
  },
  // {
  //   value: 4,
  //   label: 'Tech Lead',
  // },
];
