import ar from "vuetify/lib/locale/ar";

export default {
  ...ar,
  dashboard: "لوحة القيادة",
  applicants: "قائمة المرشحين",
  posted_jobs: "الوظائف المنشورة",
  leaderboard: "ليدربورد قائمة المتصدرين",
  settings: "الإعدادات",
  users: "المستخدمون",
  interview_stages: "مراحل المقابلة",
  templates: "قالب البريد الالكتروني",
  new: "الجديد",
  shortlisted: "قائمة مختصرة",
  schedule_interview: "جدولة مقابلة ",
  pending_decision: "نتيجة الانتظار",
  job_offered: "تم إرسال عرض العمل",
  joined: "بدأ العمل انضم",
  upcoming_interviews: "مقابلات جديدة المقابلات القادمة",
  incoming_emails: "رسائل بريد جديدة",
  published_jobs: "وظائف نشطة وظائف منشورة",
  new_applications: "طلبات جديدة",
  top_matches_by_ai_engine: "أفضل نتائج مطابقة",
  talent_funnel: "قمع الموارد البشرية مركز الموهبة",
  time_to_hire: "وقت التوظيف ",
  days_on_the_average: "ايام متوسطة ",
  applicant_sources: "مصادر المتقدم",
  add_candidate: "أضف مرشح",
  post_a_job: "انشر وظيفة",
  publish: "ينشر",
  search: "بحث",
  view_all: "مشاهدة الكل",
  email: "البريد الإلكتروني",
  social_media: "وسائل التواصل الاجتماعي",
  website: "موقع الكتروني",
  job_portal: "بوابة العمل",
  define_and_modify_interview_stages: "تحديد وتعديل مراحل المقابلة",
  edit: "يحرر تعديل",
  last_modified: "آخر تعديل",
  published_on: "تاريخ النشر",
  expire: "تنقضي / انتهى / انتهاء الصلاحية",
  draft: "مسودة",
  first_name: "الاسم الأول",
  last_name: "اسم العائلة",
  mobile_number: "رقم الهاتف المحمول",
  position_title: "لقب المسمى الوظيفي",
  experience: "خبرة",
  cover_letter: "خبرة عنوان الغلاف",
  click_here: "انقر هنا",
  create_user: "إنشاء المستخدم",
  // =================== post a job ====================
  basic_questions: "سؤال أساسي  اسئلة اساسية",
  advance_questions: "سؤال تقدم اسئلة متقدمة ",
  evaluation_form: "تثمين نموذج التقييم",
  review: "مراجعة",
  job_title: "لقب عنوان وظيفي",
  job_department: "قسم الوظيفة",
  expiry_date: "انقضاء تاريخ الانقضاء / تاريخ الانتهاء",
  enable: "خول / ممكن",
  disable: "أضعف إبطال",
  job_salary: "راتب الوظيفة",
  job_location: "موقع الوظيفة",
  job_type: "نوع الوظيفة",
  part_time: "عمل جزئي",
  full_time: "وقت كامل",
  hourly: "مرة كل ساعة",
  temporary: "مؤقت",
  remote: "عمل عن بعد",
  hiring_manager: "إداري التعيين",
  coordinator: "منسق",
  add_custom_questions: "إضافة اسئلة ",
  next: "بعد",
  use_existing_evaluation_form: "استخدام نموذج تقييم قائم",
  add_new_evaluation_form: "إضافة نموذج تقييم جديد",
  points: "نقاط",
  add_evaluation_form: "إضافة نموذج تقييم",
  publish_on_the_following_sites: "نشـر على المواقع التالية",
  some_data_missing: "بعض المعلومات ناقصة / نقص في بعض المعلومات",
  something_went_wrong: "هناك خطأ ما",
  username: "اسم االمستخدم",
  password: "كلمة المرور",
  forgot_password: "هل نسيت كلمة السر",
  //  =========== profile page ================
  candidate_info: "تفاصيل المرشح / معلومات المرشح / بيانات المرشح",
  add_note: "اضافة ملاحظات",
  edit_note: "تحرير مذكرة",
  add_evaluation: "إضافة التقييم",
  create_event: "جدولة الحدث / جدولة فعالية",
  resume: "سيرة ذاتية / خلاصة",
  score: "نتيجة",
  ai_engine_score: "نتيجة محرك ذكاء اصطناعي",
  candidate_status: "حالة المرشح",
  evaluation: "تقييم",
  questionnaire: "استبيان",
  notes: "ملاحظات",
  email_correspondence: "مراسلة عبر البريد الاكتروني",
  add: "إدراج / اضافة",
  cancel: "إنهاء / إلغاء",
  activity: "حركة / نشاط",
  logs: "سجل",
  reply: "رد",
  source: "مصدر",
  time_and_date: "الوقت و التاريخ",
  // ============ add evaluation ============
  criteria: "المعايير",
  assigned_rating: "تعيين التصنيف",
  total_rating: "التصنيف الإجمالي",
  comments: "يشرح / تعليق",
  feedback_strength_areas: "نتائج (نقاط القوة والضعف)",
  rejected: "مرفوض",
  on_hold: "في الانتظار",
  schedule_next_interview: "حدد موعد المقابلة التالية",
  recommended_for_hire: "موصى به",
  save: "إدراج / حفظ",
  rows_per_page: "صفوف لكل صفحة",
  no_data_available: "لا تتوافر بيانات",
  // ---------------- create event =================
  attendees: "الحاضرين",
  date: "تاريخ",
  from: "التاريخ من",
  to: "تاريخ ل",
  location: "موقع",
  create: "خلق / أحدث",
  profile: "الملف الشخصي",
  logout: "تسجيل خروج",
  role: "الدور الوظيفي",
  company_admin: "مسؤول الشركة",
  calendar_notifications: "Calendar Notifications",
  evaluations: "Evaluations",
  advance_dashboard: "Advance Dashboard",
  emails: "Emails",

  rejected_w_o_interview: "Rejected w o interview",
  forwarded_to_client: "Forwarded to client",
  reviewed: "reviewed",
  future_hiring: "future hiring",
  telephone_screening: "telephone screening",
  duplicate: "duplicate",
  offer_refused: "offer refused",
  forward_to_client: "forward to client",

  application_status: "Application Status",
  search_here: "بحث",
  candidate_name: "اسم المرشح",
  title: "تفاصيل",
  refresh: "تحديث",
  search_from_column: "حقل",
  applications: "مرشحين",
  loading_please_wait: "جار التحميل",
  no_data_found: "لايوجد بيانات",
  name: "اسم",
  job: "الوظيفة",
  ai_score: "نتيجة محرك ذكاء اصطناعي",
  applied_on: "تاريخ تطبيق",
  status: "الحالة",
  post: "الوظيفة",
  new_profiles: "الملف الشخصي الجديد",
  total_profiles: "الملف الشخصي الإجمالي",
  posted_on: "تاريخ النشر",
  action: "ينفذ",
  share: "Referred",
  posted_job: "وظيفة معلنة",
  date_from: "تاريخ البدء",
  date_to: "اخر موعد",
  email_sharing: "مشاركة البريد الإلكتروني",
  social_media_sharing: "مشاركة الوسائط الاجتماعية",
  interview_scheduled: "مقابلة مجدولة",
  candidates_joined: "انضم المرشح",
  total_points: "مجمل النقاط",
  enter_custom_status_here: "أدخل الحالة المخصصة هنا",
  apply_changes: "تطبيق التغييرات",
  email_templates: "قوالب البريد الإلكتروني",
  create_new_templates: "إنشاء قوالب جديدة",
  delete: "حذف",
  edit_candidate: "تعديل الملف الشخصي",
  delete_candidate: "حذف الملف الشخصي",
  email_correspondences: "رسائل البريد الإلكتروني",
  no_question_ask: "لايوجد بيانات",
  posted_by: "منشور من طرف",
  recommendation: "توصية",
  interview_by: "مقابلة بواسطة",
  no_evaluation_yet: "لايوجد بيانات",
  no_emails_at_moment: "لايوجد بيانات",
  interviews: "المقابلات",
  details: "تفاصيل",
  events: "الأحداث",
  no_events_at_moment: "لايوجد بيانات",
  start_time: "وقت البدء",
  end_time: "وقت النهاية",
  candidate: "مرشح",
  phone_number: "رقم الهاتف",
  cv_resume: "سيرة ذاتية",
  save_changes: "احفظ التغييرات",
  add_notes: "أضف ملاحظات",
  employees: "Employees",
  event_title: "عنوان الحدث",
  data_is_missing: "بيانات مفقودة",
  job_deleted: "حذف الوظيفة",
  option: "خيار",
  add_option: "إضافة خيار",
  add_question: "أضف سؤال",
  question: "سؤال",
  type: "نوع",
  required: "إلزامي",
  weekly: "أسبوعي",
  remotely: "عبر الانترنت",
  type_here: "أكتب هنا",
  on_hole: "يؤجل",
  send_message: "إرسال رسالة",
  subject: "موضوعات",
  attachments: "التعلق",
  candidate_emails: "البريد الإلكتروني المرشح",
  user: "مستخدم",
  contact: "رقم الاتصال",
  update: "تحديث",
  template: "قوالب",
  evaluation_details: {
    feedback: "رد الفعل لشئ ما (الإيجابيات والتحسينات)",
  },
  // pages
  setting_page: {
    interview_stages: {
      title: "تحديد وتعديل مراحل المقابلة",
    },
  },
  job_post: {
    hiring_manager: "إدارة/منسق",
    interviewers: "مقابلة بواسطة",
    job_type: "نوع الوظيفة",
    job_location: "موقع الاحتلال",
    job_salary: "راتب",
    expiry_date: "وقت النهاية",
    job_department: "قسم",
    job_title: "تفاصيل",
    review: "إعادة النظر",
    form_evaluation: "استمارة تقييم",
    advance_questions: "أسئلة الفرز",
    basic_questions: "أسئلة أساسية",
    add_custom_questions: "إضافة أسئلة مخصصة",
    new_question: "أسئلة جديدة",
    question_text: "نص السؤال",
    current_option: "الخيار الحالي",
    evaluation_form_selected: "تيملات المختارة",
    select_from_existing: "اختر من النموذج الموجود",
    add_new_evaluation: "اضافة استمارة تقييم جديدة",
    use_existing_evaluation: "استخدم النموذج الموجود",
    add_evaluation_form: "إضافة استمارة التقييم",
    publish_on_following: "انشر على المواقع التالية",
    company_website: "موقع الشركة",
    save_as_draft: "حفظه كمسودة أو نشر؟",
    current_status: "الوضع الحالي هو",
    positions: "المناصب",
  },
  popup: {
    delete_candidate: {
      danger_text:
        " بحذف هذا الحساب ، سيتم حذف جميع بيانات المستخدم المرتبطة بهذا المرشح نهائيًا ، ولا يمكن التراجع عنه.",
      proceed_text:
        "To proceed, type DELETE (in ALL CAPS) to delete the user account and all of it’s data permanently..",
    },
    posted_job: {
      delete_job: "هل تريد حذف هذه الوظيفة?",
      edit: "هل تريد تعديل هذه الوظيفة ?",
    },
    social_share: {
      invite_candidate: "ادعُ المرشح",
      example:
        "بالنسبة إلى رسائل البريد الإلكتروني المتعددة ، استخدم الفصل بفاصلة",
    },
    auto_interview: {
      information_text:
        "Are you sure you want to send this user Automated Interview Link?",
    },
  },
  hired_days: "أيام التوظيف",
  delete_job: "حذف الوظيفة",
  company: "شركة",
  packages: "الحزم",
  // -------------------------Interview Results-----------------------
  interview_results: "نتائج المقابلة",
  posted_date: "Date posted",
  closing_date: "Closing Date",
  candidates: "Candidates",
  attempted: "Attempted",
  preview: "Preview",
  invited_on: "Invited on",
  completed_on: "Completed on",
  interviewers_rating: "Interviewers Rating",
  auto_interviews: "Auto Interviews",
  automated_interview_list: "Automated Interview List",
  interviews_requested: "Interviews Requested",
  question_type: "Question Type",
  reading_time: "Time to Read",
  answer_time: "Time to Answer",
  screen_share: "Screen Share",
  order_no: "Question Order",
  answer_url: "Answer URL",
  job_status: "Job Status",
  initiate_auto_interview: "Initiate Auto Interview",
  view_recording: "View Recording",
  recorded_interview: "Recorded Interview",
  send: "send",
};
