// export const API_END_POINT = "https://api.dev.airec.io";
export const API_END_POINT = "https://api.airec.io";
export const GOOGLE_API_KEY = "AIzaSyBKfk7U016EQkW_6zcmxb5mnwYyRZMJvto";
export const GOOGLE_CLIENT_ID =
  "70954835642-0n6jnu98tshpmkjjafun9a81ac893co9.apps.googleusercontent.com";
export const SCOPES = {
  CALENDAR: "https://www.googleapis.com/auth/calendar",
};
export const GOOGLE_DISCOVERY_DOCS = {
  CALENDAR: "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
};

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBKfk7U016EQkW_6zcmxb5mnwYyRZMJvto",
  authDomain: "fir-e2e.firebaseapp.com",
  projectId: "fir-e2e",
  storageBucket: "fir-e2e.appspot.com",
  messagingSenderId: "434515956700",
  appId: "1:434515956700:web:3a88e37fe9fa4c36f9b519",
  measurementId: "G-13330C7CE1",
};
