import Vue from 'vue';
import axios from 'axios';
import get from 'lodash/get';
import { API_END_POINT } from '@/config/index.js';

const service = axios.create({
  baseURL: API_END_POINT,
});

service.interceptors.response.use(
  (response) => {
    let token = response.data.token;
    if (token) {
      localStorage.setItem('token', token);
    }
    return response;
  },
  (error) => {
    let errorMsg = get(
      error,
      'response.data.message',
      'Something went wrong axios'
    );

    if (
      error?.response?.data?.status === 'unauthorized' ||
      error?.response?.data === 'Unauthenticated.'
    ) {
      localStorage.clear();
      errorMsg = 'You are not authorized';
    }

    Vue.notify({
      group: 'backend-error',
      title: 'Error',
      text: errorMsg,
      type: 'error',
      duration: 5000,
    });

    // localStorage.removeItem("token");
    return Promise.reject(error);
  }
);

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Vue.notify({
      group: 'backend-error',
      title: 'Error',
      text: error.response.data.msg || 'Something went wrong...!',
      type: 'error',
      duration: 5000,
    });

    // localStorage.removeItem("token");
    return Promise.reject(error);
  }
);

export default service;
