import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import goTo from 'vuetify/lib/services/goto';

Vue.use(VueRouter);

const adminMeta = {
  requiresAuth: true,
  roles: [0],
};

const routes = [
  {
    path: '',
    component: () => import('@/layouts/DashboardLayout'),
    children: [
      {
        path: '/',
        name: 'Dashboard',
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
        component: () => import('@/views/Dashboard'),
      },
      {
        path: '/application',
        name: 'application',
        component: () => import('@/views/application/application'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/leaderboard',
        name: 'leaderboard',
        component: () => import('@/views/leaderboard/leaderboard'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/application/:id',
        name: 'applicant-profile',
        component: () => import('@/views/applicant/ApplicantProfile'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/job-post/:id?',
        name: 'job-post',
        component: () => import('@/views/job-post/job-post'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/posted-jobs',
        name: 'posted-jobs',
        component: () => import('@/views/posted-jobs/posted-jobs'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/interview-results',
        name: 'interview-results',
        component: () => import('@/views/interview-results/interview-results'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/interview-results/:id',
        name: 'interview-results',
        component: () => import('@/views/interview-results/job-screening'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/automated-interviews',
        name: 'automated-interviews',
        component: () => import('@/views/automated-interviews/automated-interviews'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/automated-interviews/candidate/:id',
        name: 'candidate-info',
        component: () => import('@/views/automated-interviews/candidate-info'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      {
        path: '/posted-jobs/:status/:id',
        name: 'posted-jobs',
        component: () => import('@/views/posted-jobs/job'),
        meta: {
          requiresAuth: true,
          roles: [1, 2, 3, 4],
        },
      },
      //  setting pages
      {
        path: '/setting/users',
        name: 'setting-users',
        component: () => import('@/views/setting/users'),
        meta: {
          requiresAuth: true,
          roles: [1, 2],
        },
      },
      {
        path: '/setting/templates',
        name: 'setting-templates',
        component: () => import('@/views/setting/templates'),
        meta: {
          requiresAuth: true,
          roles: [1, 2],
        },
      },
      {
        path: '/setting/company',
        name: 'setting-company',
        component: () => import('@/views/setting/company'),
        meta: {
          requiresAuth: true,
          roles: [1, 2],
        },
      },
      {
        path: '/setting/interview-stages',
        name: 'setting-interview-stages',
        component: () => import('@/views/setting/interview-stages'),
        meta: {
          requiresAuth: true,
          roles: [1, 2],
        },
      },
      {
        path: '/setting/interview-questions',
        name: 'setting-interview-questions',
        component: () => import('@/views/setting/interview-questions'),
        meta: {
          requiresAuth: true,
          roles: [1, 2],
        },
      },
      {
        path: '/add-interview-question',
        name: 'add-interview-question',
        component: () => import('@/views/setting/add-interview-question'),
        meta: {
          requiresAuth: true,
          roles: [1, 2],
        },
      },
      {
        path: '/profile',
        name: 'user-profile',
        component: () => import('@/views/user-profile/Profile'),
        meta: {
          requiresAuth: true,
          roles: [0, 1, 2, 3, 4],
        },
      },

      // ========================= SYSTEM ADMIN ROUTES ============================
      {
        path: '/system-admin/company',
        name: 'system-admin-company',
        component: () => import('@/views/system-admin/company.vue'),
        meta: adminMeta,
      },
      {
        path: '/system-admin/users',
        name: 'system-admin-users',
        component: () => import('@/views/system-admin/users.vue'),
        meta: adminMeta,
      },
      {
        path: '/system-admin/location',
        name: 'system-admin-locations',
        component: () => import('@/views/system-admin/locations.vue'),
        meta: adminMeta,
      },
      {
        path: '/system-admin/dashboard',
        name: 'admin-dashboard',
        component: () => import('@/views/system-admin/dashboard.vue'),
        meta: adminMeta,
      },
      {
        path: '/system-admin/packages',
        name: 'admin-dashboard',
        component: () => import('@/views/system-admin/packages.vue'),
        meta: adminMeta,
      },
    ],
  },
  // blank pages route
  {
    path: '',
    component: () => import('@/layouts/BlankLayout'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          requiresAuth: false,
          roles: [],
        },
        component: () => import('@/views/auth/Login'),
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: {
          requiresAuth: false,
          roles: [],
        },
        component: () => import('@/views/auth/ForgotPassword'),
      },
    ],
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/views/404'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { requiresAuth, roles } = to.meta;
  let isAuthenticated = false;

  const token = localStorage.getItem('token');

  if (token) {
    const { user } = Store.state;
    if (!user) {
      try {
        Store.commit('SET_APP_LOADING', true);
        await Store.dispatch('FETCH_USER');
        isAuthenticated = true;
      } catch (error) {
        console.log('{ROUTER INDEX}', error);
      }
      setTimeout(() => {
        Store.commit('SET_APP_LOADING', false);
      }, 1900);
    } else {
      isAuthenticated = true;
    }
  }

  if (requiresAuth && isAuthenticated) {
    const { user } = Store.state;
    if (roles.length && roles.includes(+user.role)) {
      next();
    } else {
      if (+user.role === 0) {
        next('/system-admin/dashboard');
      } else {
        next('/');
      }
    }
  } else if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (!requiresAuth && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
