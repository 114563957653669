<template>
  <v-app>
    <router-view />
    <notifications
      group="backend-error"
      position="top right"
      animation-type="velocity"
      class="error-notification"
    />

    <v-overlay :value="appLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import {
  GOOGLE_API_KEY,
  GOOGLE_CLIENT_ID,
  SCOPES,
  GOOGLE_DISCOVERY_DOCS
} from "@/config";
export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapState(["appLoading"])
  },
  methods: {
    loadGAPI() {
      const gapi = window.gapi;
      gapi.load("client", {
        callback() {
          gapi.client.init({
            apiKey: GOOGLE_API_KEY,
            clientId: GOOGLE_CLIENT_ID,
            discoveryDocs: [GOOGLE_DISCOVERY_DOCS.CALENDAR],
            scope: SCOPES.CALENDAR
          });
          gapi.client.load("calendar", "v3");
        },
        onerror(e) {
          console.log("error", e);
        }
      });
    }
  },
  mounted() {
    this.loadGAPI();
  }
};
</script>
