import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins";
import vuetify from "./plugins/vuetify";

import "@/scss/main.scss";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  components: { App },
}).$mount("#app");
