import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "@/axios";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import "./filters";

import Card from "@/components/card/card";
import Loader from "@/components/loader/loader";
import Clipboard from "v-clipboard";
import VueHtml5Editor from "vue-html5-editor";

Vue.component("Card", Card);
Vue.component("Loader", Loader);

Vue.use(VueAxios, axios);
Vue.use(Notifications, { velocity });
Vue.use(Clipboard);

const options = {
  visibleModules: [
    "text",
    "color",
    "font",
    "align",
    "list",
    "tabulation",
    "hr",
    "eraser",
    "undo",
  ],
  icons: {
    text: "fa fa-pencil-alt",
  },
};

Vue.use(VueHtml5Editor, options);
