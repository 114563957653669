<template>
  <div
    class="card"
    :class="`elevation-${elevation}`"
    :style="`width:${width};max-width:${maxWidth}`"
    :key="$vuetify.rtl"
  >
    <div
      class="card--header"
      v-if="title"
      :style="{ 'background-color': headerColor }"
    >
      <div class="title">
        <p class="mb-0">{{ title }}</p>
        <v-chip
          v-if="badge"
          class="mx-2"
          x-small
          :color="badgeColor"
          :text-color="badgeTextColor"
        >
          {{ badge }}
        </v-chip>
      </div>
      <div class="action" v-if="!hideCollapse">
        <slot name="header-action">
          <v-btn
            text
            class="secondary--text"
            :to="headerLink"
            v-if="headerLink"
          >
            {{ $vuetify.lang.t("$vuetify.view_all") }}
            <v-icon color="secondary"
              >mdi-menu-{{ `${$vuetify.rtl ? "left" : "right"}` }}</v-icon
            ></v-btn
          >
        </slot>
        <v-btn icon @click="hideBody = !hideBody" v-if="!hideDownBtn">
          <v-icon>{{ `mdi-menu-${!hideBody ? "down" : "up"}` }}</v-icon>
        </v-btn>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div
        class="card--body"
        :class="`${noPadding ? 'no-padding' : ''}`"
        :style="{ backgroundColor: bgColor }"
        v-if="hideBody"
        :key="loading"
      >
        <loader :loading="loading" :overlay="false">
          <slot />
        </loader>
      </div>
    </transition>

    <div class="card--footer" v-if="hideBody">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    title: {
      type: String,
      default: "",
    },
    badge: {
      type: String,
      default: "",
    },
    badgeColor: {
      type: String,
      default: "#6c757d",
    },
    badgeTextColor: {
      type: String,
      default: "#fff",
    },
    headerLink: {
      type: String,
      default: "",
    },
    headerColor: {
      type: String,
      default: "#cbd9e2",
    },
    hideCollapse: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideDownBtn: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "#f3f3f3",
    },
    elevation: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      hideBody: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  overflow: hidden;
  margin-bottom: 1.5rem;
  direction: inherit;

  &--header {
    padding: 0.6rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .title {
      display: flex;
      align-items: center;

      p {
        font-size: 0.8rem;
      }
    }

    .action {
      a {
        text-decoration: none;
      }
    }
  }

  &--body {
    padding: 1.25rem;
    position: relative;

    &.no-padding {
      padding: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
