import Vue from "vue";

import moment from "moment";
import { UTC_TO_LOCAL, get_user_role } from "@/helpers";

Vue.filter("limitChar", (value, limit = 15) => {
  if (value) {
    if (value.length <= limit) return value;
    let data = value.substring(0, limit);
    return data + "...";
  }
  return "";
});

Vue.filter("roleFilter", get_user_role);

Vue.filter("date", (value, format = "MMMM D YYYY") => {
  return UTC_TO_LOCAL(value, format);
});

Vue.filter("formatFromNow", function(value) {
  // let d = moment(value).format("MMMM Do YYYY")
  if (value) {
    value = moment.utc(value).toDate();
    return moment(value).fromNow();
  }
});

Vue.filter("datetime", function(value) {
  let localTime = moment.utc(value).toDate();
  let d = moment(localTime).format("MMMM D YYYY h:mm A");
  return d;
});
