import service from '../../axios/index.js';

export default {
  namespaced: true,
  state: {
    features: [],
    packages: [],
    loading: false,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_PACKAGES(state, { packages, features }) {
      state.packages = packages;
      state.features = features;
    },
  },
  actions: {
    async FETCH_PACKAGES({ commit }) {
      try {
        commit('SET_LOADING', true);
        const [featureResp, packagesResp] = await Promise.all([
          service.get('/company-features'),
          service.get('/packages'),
        ]);

        const features = featureResp.data.data;
        const packages = packagesResp.data.data;

        commit('SET_PACKAGES', { features, packages });
      } catch (error) {
        console.log(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};
