import en from "vuetify/lib/locale/en";

export default {
  ...en,
  dashboard: "Dashboard",
  applicants: "Applicants",
  posted_jobs: "Posted Jobs",
  leaderboard: "Leaderboard",
  settings: "Settings",
  users: "Users",
  interview_stages: "Interview Stages",
  templates: "Templates",
  company: "Company",

  new: "New",
  shortlisted: "Shortlisted",
  schedule_interview: "Schedule interview",
  pending_decision: "Pending Decision",
  job_offered: "Job offered",
  joined: "Joined",
  upcoming_interviews: "Upcoming interviews",
  incoming_emails: "Incoming Emails",
  published_jobs: "Published Jobs",
  new_applications: "New Applications",
  top_matches_by_ai_engine: "Top Matches by AI-Engine",
  talent_funnel: "Talent Funnel",
  time_to_hire: "Time to Hire",
  days_on_the_average: "Days on the average",
  applicant_sources: "Applicant Sources",

  add_candidate: "Add Candidate",
  post_a_job: "Post a Job",
  publish: "Publish",
  search: "Search",
  view_all: "View All",

  email: "Email",
  social_media: "Social media",
  website: "Website",

  job_portal: "Job portal",
  define_and_modify_interview_stages: "Define and modify interview stages",
  edit: "Edit",
  last_modified: "Last modified",
  published_on: "Published on",
  expire: "Expire",
  draft: "Draft",
  first_name: "First name",
  last_name: "Last name",
  mobile_number: "Mobile number",
  position_title: "Position Title",
  experience: "Experience",
  cover_letter: "Cover letter",
  click_here: "Click here",
  create_user: "Create user",
  // =================== post a job ====================
  basic_questions: "Basic Questions",
  advance_questions: "Advance Questions",
  evaluation_form: "Evaluation Form",
  review: "Review",
  job_title: "Job Title",
  job_department: "Job Department",
  expiry_date: "Expiry date",
  enable: "Enable",
  disable: "Disable",
  job_salary: "Job Salary",
  job_location: "Job location",
  job_type: "Job type",
  part_time: "Part time",
  full_time: "Full time",
  hourly: "Hourly",
  temporary: "Temporary",
  remote: "Remote",
  hiring_manager: "Hiring Manager",
  coordinator: "Coordinator",
  add_custom_questions: "Add Custom Questions",
  next: "Next",
  use_existing_evaluation_form: "Use existing evaluation form",
  add_new_evaluation_form: "Add new evaluation form",
  points: "Points",
  add_evaluation_form: "Add Evaluation form",
  publish_on_the_following_sites: "Publish on the following sites",
  some_data_missing: "Some data missing",
  something_went_wrong: "Something went wrong",
  username: "username",
  password: "Password",
  forgot_password: "Forgot password",
  //  =========== profile page ================
  candidate_info: "Candidate info",
  add_note: "Add Note",
  edit_note: "Edit Note",
  add_evaluation: "Add Evaluation",
  create_event: "Create Event",
  resume: "Resume",
  score: "Score",
  ai_engine_score: "AI-Engine score",
  candidate_status: "Candidate status",
  evaluation: "Evaluation",
  questionnaire: "Questionnaire",
  notes: "Notes",
  email_correspondence: "Email correspondence",
  add: "Add",
  cancel: "Cancel",
  activity: "Activity",
  logs: "Logs",
  reply: "Reply",
  source: "Source",
  time_and_date: "Time & Date",
  // ============ add evaluation ============
  criteria: "Criteria",
  assigned_rating: "Assigned rating",
  total_rating: "Total rating",
  comments: "Comments",
  feedback_strength_areas: "Feedback (Strengths / Areas of improvement)",
  rejected: "Rejected",
  on_hold: "On Hold",
  schedule_next_interview: "Schedule next interview",
  recommended_for_hire: "Recommended for Hire",
  save: "Save",
  ShowDetails: "Show Details",
  rows_per_page: "Rows per page",
  no_data_available: "No data available",
  // ---------------- create event =================
  attendees: "Attendees",
  date: "Date",
  from: "From",
  to: "To",
  location: "Location",
  create: "Create",
  profile: "Profile",
  logout: "Logout",
  role: "Role",
  company_admin: "Company Admin",
  // ---------------- company details =================
  address: "Address",
  created_at: "Created at",
  email_address: "Email address",
  updated_at: "Updated at",
  url: "Url",
  name: "Name",
  companies: "Companies",
  calendar_notifications: "Calendar Notifications",
  evaluations: "Evaluations",
  advance_dashboard: "Advance Dashboard",
  emails: "Emails",

  // not available
  rejected_w_o_interview: "Rejected w o interview",
  forwarded_to_client: "Forwarded to client",
  reviewed: "reviewed",
  future_hiring: "future hiring",
  telephone_screening: "telephone screening",
  duplicate: "duplicate",
  offer_refused: "offer refused",
  forward_to_client: "forward to client",
  application_status: "Application Status",
  search_here: "Search Here",
  candidate_name: "Candidate Name",
  title: "Title",
  refresh: "Refresh",
  search_from_column: "Search From Column",
  applications: "Applications",
  loading_please_wait: "Loading Please Wait",
  no_data_found: "No Data Found",
  job: "Job",
  ai_score: "Ai Score",
  applied_on: "Applied On",
  status: "Status",
  post: "Post",
  new_profiles: "New Profiles",
  total_profiles: "Total Profiles",
  posted_on: "Posted_on",
  action: "Action",
  share: "Share",
  posted_job: "Posted Job",
  date_from: "Date From",
  date_to: "Date To",
  email_sharing: "Email Sharing",
  social_media_sharing: "Social Media Sharing",
  interview_scheduled: "Interview Scheduled",
  candidates_joined: "Candidates Joined",
  total_points: "Total Points",
  enter_custom_status_here: "Enter Custom Status Here",
  apply_changes: "Apply Changes",
  email_templates: "Email Templates",
  create_new_templates: "Create New Templates",
  delete: "delete",
  edit_candidate: "Edit Candidate",
  delete_candidate: "Delete Candidate",
  email_correspondences: "Email Correspondences",
  no_question_ask: "No Question Ask...",
  posted_by: "Posted By",
  recommendation: "Recommendation",
  interview_by: "Interview By",
  no_evaluation_yet: "No Evaluation Yet",
  no_emails_at_moment: "No emails at the moments",
  interviews: "Interviews",
  details: "Details",
  events: "Events",
  no_events_at_moment: "No Events at the moments.",
  start_time: "Start Time",
  end_time: "End Time",
  candidate: "Candidate",
  phone_number: "Phone Number",
  cv_resume: "CV/ Resume",
  save_changes: "Save Changes",
  add_notes: "Add Notes",
  employees: "Employees",
  event_title: "Event Title",
  data_is_missing: "Some data is missing",
  job_deleted: "Job Deleted",
  option: "Option",
  add_option: "Add Option",
  add_question: "Add Question",
  question: "Question",
  type: "Type",
  required: "Required",
  weekly: "Weekly",
  remotely: "Remotely",
  type_here: "Type Here",
  on_hole: "On Hold",
  send_message: "Send Message",
  subject: "Subject",
  attachments: "Attachments",
  candidate_emails: "Candidate Emails",
  user: "User",
  contact: "Contact",
  update: "Update",
  template: "Template",
  evaluation_details: {
    feedback: "Feedback (Strength, Areas of Interest)",
  },
  // pages
  setting_page: {
    interview_stages: {
      title: "Define and Modify Interview Stages",
    },
  },
  job_post: {
    hiring_manager: "Hiring Manager/Coordinator",
    interviewers: "Interviewers",
    job_type: "Job Type",
    job_location: "Job Location",
    job_salary: "Job Salary",
    expiry_date: "Expiry Date",
    job_department: "Job Department",
    job_title: "Job Title",
    review: "Review",
    form_evaluation: "Form Evaluation",
    advance_questions: "Advance Questions",
    basic_questions: "Basic Questions",
    add_custom_questions: "Add Custom Questions",
    new_question: "New Question",
    question_text: "Question Text",
    current_option: "Current Option",
    evaluation_form_selected: "Evaluation  Form Selected",
    select_from_existing: "Select from existing form",
    add_new_evaluation: "Add new evaluation form",
    use_existing_evaluation: "Use Existing Evaluation",
    add_evaluation_form: "Add Evaluation Form",
    publish_on_following: "Publish on the following sites",
    company_website: "Company Website",
    save_as_draft: "Save it as a draft or publish?",
    current_status: "Current status is",
    positions: "Positions",
  },
  popup: {
    delete_candidate: {
      danger_text:
        " By deleting this account, all the user data associated with this candidate will be deleted PERMANENTLY, which CANNOT be undone.",
      proceed_text:
        "To proceed, type DELETE (in ALL CAPS) to delete the user account and all of it’s data permanently..",
    },
    posted_job: {
      delete_job: "Do you want to delete this job ?",
      edit: "Do you want to edit this job ?",
    },
    social_share: {
      invite_candidate: "Invite Candidate",
      example: "For multiple emails use comma separation e.g",
    },
    auto_interview: {
      information_text:
        "Are you sure you want to send this user Automated Interview Link?",
    },
  },
  hired_days: "Hired Days",
  delete_job: "Delete Job",
  packages: "packages",
  // -------------------------Interview Results-----------------------
  interview_results: "Interview Results",
  posted_date: "Date posted",
  closing_date: "Closing Date",
  candidates: "Candidates",
  attempted: "Attempted",
  preview: "Preview",
  invited_on: "Invited on",
  completed_on: "Completed on",
  interviewers_rating: "Interviewers Rating",
  auto_interviews: "Auto Interviews",
  automated_interview_list: "Automated Interview List",
  interviews_requested: "Interviews Requested",
  question_type: "Question Type",
  reading_time: "Time to Read",
  answer_time: "Time to Answer",
  screen_share: "Screen Share",
  order_no: "Question Order",
  answer_url: "Answer URL",
  job_status: "Job Status",
  initiate_auto_interview: "Initiate Auto Interview",
  view_recording: "View Recording",
  recorded_interview: "Recorded Interview",
  send: "send",
};
