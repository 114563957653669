import moment from 'moment';
export const UTC_TO_LOCAL = (date, format) => {
  let localTime = moment.utc(date).toDate();
  let d = moment(localTime).format(format);
  return d;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const get_user_role = (value) => {
  if (value == 0) {
    return 'System Admin';
  }
  if (value == 1) {
    return 'Super Admin';
  }
  if (value == 2) {
    return 'Company Admin';
  }
  if (value == 3) {
    return 'Employee';
  }
  if (value == 4) {
    return 'Tech Lead';
  }
};
