import service from '../../axios/index.js';

export default {
  namespaced: true,
  state: {
    loading: false,
    companies: [],
  },
  mutations: {
    SET_COMPANIES(state, companies) {
      state.companies = companies;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async FETCH_ALL_COMPANIES({ commit }) {
      try {
        commit('SET_LOADING', true);
        const resp = await service.get('/company');
        const data = resp.data;
        console.log(data);
        commit('SET_COMPANIES', data);
      } catch (error) {
        console.log(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};
